import { FieldsWrapper } from '@app/components/atm.wrapper/wrapper.component'
import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { PageProps } from '@app/utils/local-types'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { getFaqJsonLd } from '@root/src/components/legacy/mol.seo/build-faq-json-ld'
import { FuseSearch } from '@root/src/components/mol.local-search/fuse-search.component'
import { IndexParams } from '@root/src/components/mol.local-search/fuse-search.utils'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { CosmicjsInformacoesEstaticas, Query } from '@root/src/data/graphql/graphql-types'
import { Body, Col, Divisor, Grid, H2, H3, Row } from 'atomic'
import { graphql, Link } from 'gatsby'
import React from 'react'

interface Question {
  question: string
  answer: string
  slug: string
}

export interface FaqTemplateProps {
  query: Query
  questions: Question[]
}

interface PageData {
  title: string
  searchPlaceholder: string
  col1Title: string
  questions: Question[]
  col2Title: string
  col2HtmlContent: string
}

const FaqTemplate: React.FunctionComponent<PageProps<FaqTemplateProps>> = props => {
  const staticInfo: CosmicjsInformacoesEstaticas = props.data.cosmicjsInformacoesEstaticas
  const pageData: PageData = {
    title: staticInfo.metadata.titulo,
    searchPlaceholder: staticInfo.metadata.buscaPlaceholder,
    col1Title: staticInfo.metadata.col1Titulo,
    col2Title: staticInfo.metadata.col2Titulo,
    col2HtmlContent: staticInfo.metadata.col2Conteudo,
    questions: props.pageContext.questions
  }
  const [items, setItems] = React.useState(pageData.questions)
  const onResult = list => {
    if (list) {
      setItems(list)
    }
  }
  const indexParams: IndexParams<Question> = {
    data: pageData.questions,
    keys: [
      {
        name: 'question',
        weight: 0.6
      },
      {
        name: 'answer',
        weight: 0.4
      }
    ]
  }
  return (
    <IndexLayout location={props.location}>
      <Grid>
        <SEO
          jsonld={getFaqJsonLd(
            pageData.questions.map(item => ({ Question: item.question, Answer: item.answer }))
          )}
          socialMedia={{
            canonicalUrl: `${props.data.site.siteMetadata.siteUrl}${props.location.pathname}${props.location.search}`,
            title: staticInfo.metadata.seo.titulo,
            image: staticInfo.metadata.seo.imagem.url,
            imageAlt: staticInfo.metadata.seo.imagemAlternativa,
            description: staticInfo.metadata.seo.descricao
          }}
        />
        <TitleWithBreadcrumbRow title={pageData.title} />
        <Row mb>
          <Col xs={12} md={8}>
            <FieldsWrapper>
              <FuseSearch
                id="input-faq-list-search"
                indexParams={indexParams}
                onResultChange={onResult}
                placeholder={pageData.searchPlaceholder}
              />
            </FieldsWrapper>
          </Col>
        </Row>
        <Row mb>
          <Col xs={12} md={8}>
            <Row mb>
              <H2>{pageData.col1Title}</H2>
            </Row>
            <Row mb>
              {items.map((question, index) => (
                <React.Fragment>
                  <Link to={question.slug}>
                    <H3>{question.question}</H3>
                  </Link>
                  {index !== pageData.questions.length - 1 && <Divisor />}
                </React.Fragment>
              ))}
            </Row>
          </Col>
          <Col xs={12} mdOffset={1} md={3}>
            <H2>{pageData.col2Title}</H2>
            <Body>
              <div dangerouslySetInnerHTML={{ __html: pageData.col2HtmlContent }} />
            </Body>
          </Col>
        </Row>
        <LargeSeparatorRow />
      </Grid>
    </IndexLayout>
  )
}

export default FaqTemplate

export const query = graphql`
  query FaqTemplate {
    cosmicjsInformacoesEstaticas(slug: { eq: "perguntas-frequentes" }) {
      metadata {
        titulo
        buscaPlaceholder
        col1Titulo
        col2Titulo
        col2Conteudo
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
